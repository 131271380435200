import React from "react"
import classNames from "classnames"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  shuffleArray,
  getUnrelatedReviews,
  getHearFromHeadingAndBlurbObject,
  isFacewallTextGeneric
} from "../utils/utils"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { Button } from "../components/Button"

import HeadingBlurbMedia from "../components/BodyElements/HeadingBlurbMedia"
import MarkdownSection from "../components/BodyElements/MarkdownSection"
import FeaturedList from "../components/BodyElements/FeaturedList"
import GenericSection from "../components/BodyElements/GenericSection"
import ImageText from "../components/BodyElements/ImageText"
import FixedFacewall from "../components/carousel/FixedFacewall"
import ReviewRowsSection from "../components/ReviewRowsSection"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"
import DropdownButton from "../components/DropdownButton"
import ImageStrip from "../components/ImageStrip/ImageStrip"
import { Columns, Section } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import Video from "../components/Video"
import SingleProcedureHero from "../components/SingleProcedureHero"

const SingleProcedure = ({ data, pageContext, location }) => {
  const post = data.allProcedures.nodes[0]
  const { metaDescription, metaTitle, language } = pageContext

  // hide facewall if reviews query is turned off
  // if it is off, it probably means site has no reviews
  const reviewsPageIsOn = data.hasReviews.nodes[0].reviews

  // SDOS classNames
  const HeadingBlurbMediaDI =
    post.name === "Dental Implants" ? "Dental Implants" : ""

  const getStartedData =
    language === "es"
      ? data.allTemplateSettingsJson.nodes[0].getStartedSpanish
      : data.allTemplateSettingsJson.nodes[0].getStarted

  let relatedReviews = []
  let allReviews = []

  if (data.relatedReviews.nodes) relatedReviews = data.relatedReviews.nodes
  if (data.allReviews.nodes) allReviews = data.allReviews.nodes

  shuffleArray(relatedReviews)

  const originalReviewCount = relatedReviews.length

  const unrelatedReviews = getUnrelatedReviews(
    relatedReviews,
    allReviews,
    "title"
  )

  shuffleArray(unrelatedReviews)

  let fixedFacewallReviews = []
  let reviewRowsReviews = []

  // Limit leftover reviews to 5, all reviews per page to 9
  let relatedReviewsLength = relatedReviews.length
  if (relatedReviewsLength > 4 + 5) {
    relatedReviewsLength = 9
  }

  // If longer than 4, Split reviews into facewall (4) and review rows (up to 5)
  if (originalReviewCount > 4) {
    fixedFacewallReviews = relatedReviews.slice(0, 4)
    reviewRowsReviews = relatedReviews.slice(4, relatedReviewsLength)
  }

  // Else, fill facewall with unrelated reviews until 4 long
  else if (originalReviewCount < 4) {
    fixedFacewallReviews = relatedReviews
    while (fixedFacewallReviews.length < 4 && unrelatedReviews.length >= 3) {
      fixedFacewallReviews.push(unrelatedReviews.pop())
    }
  } else {
    fixedFacewallReviews = relatedReviews
  }

  function BodySection(props) {
    if (props.type === "textSection") {
      return (
        <MarkdownSection
          colorBack={props.colorBack}
          textSection={props.textSection}
          buttons={props.buttons}
          sideColumnIs={props.sideColumnIs}
        />
      )
    }
    if (props.type === "imageSection") {
      return (
        <div className="body-section">
          <Columns sideColumnsSize={props.sideColumnIs}>
            <div className="column">
              <Image publicId={props.image} />
            </div>
          </Columns>
        </div>
      )
    }
    if (props.type === "videoSection") {
      return (
        <div className="body-section">
          <Columns sideColumnsSize={props.sideColumnIs}>
            <div className="column">
              <Video videoUrl={props.url} isSizzle />
            </div>
          </Columns>
        </div>
      )
    }
    if (props.type === "headingBlurbMedia") {
      return <HeadingBlurbMedia {...props} customStyle={HeadingBlurbMediaDI} />
    }
    if (props.type === "featuredList") {
      return (
        <FeaturedList
          listContent={props}
          headingSideColumnIs={5}
          listSideColumnIs={5}
        />
      )
    }
    if (props.type === "fixedFacewall") {
      if (fixedFacewallReviews.length >= 4 && language !== "es") {
        return (
          <FixedFacewall
            blurb={fixedFacewallBlurb}
            heading={fixedFacewallHeading}
            sideColumnIs={1}
            middleColumnsis={1}
            reviews={fixedFacewallReviews}
          />
        )
      }
    } else if (props.type === "anesthesiaModal") {
      return (
        <div className="single-procedure-anesthesia">
          <AnesthesiaModal bodySections procedurePage />
        </div>
      )
    } else if (props.type === "imageStripSection") {
      return <ImageStrip images={props.imageStrip} />
    } else if (props.type === "genericSection") {
      return <GenericSection {...props} />
    } else if (props.type === "imageText") {
      return (
        <ImageText
          sideColumnIs={props.sideColumnIs}
          textSection={props.textSection}
          extraText={props.extraText}
          imageId={props.imageId}
          heading={props.heading}
          imageTextReverse={props.imageTextReverse}
        />
      )
    } else {
      return <></>
    }
  }

  let bodySections

  bodySections = post.bodySections.map((section, i) => (
    <React.Fragment key={i}>
      <BodySection {...section} />
    </React.Fragment>
  ))

  const headingAndBlurb = getHearFromHeadingAndBlurbObject(
    post,
    originalReviewCount
  )

  var fixedFacewallHeading = headingAndBlurb.heading
  var fixedFacewallBlurb = headingAndBlurb.blurb

  let backUrl = ""

  if (data.allUniquePagesJson?.nodes[0]?.title) {
    backUrl = `/${data.allUniquePagesJson.nodes[0].title}`
  }

  const facewallClasses = classNames("procedure-facewall", {
    generic: isFacewallTextGeneric(post, originalReviewCount)
  })

  // for schema data
  // collect all content and combine to one string
  const combineText = (...args) =>
    args
      .join(" ")
      .replace(/<[^>]*>?/gm, "")
      .replace(/[$@%*#\n]/g, "")

  const pageContent = combineText(
    post.heading,
    post.bodySections.map(s => s.textSection).join(" "),
    post.faqSection.text,
    post.getStarted.heading,
    post.getStarted.blurb
  )

  let schemaData
  if (language === "en" && post.schemas?.presets?.length > 0) {
    schemaData = {
      presets: post.schemas.presets,
      customSchema: post.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: metaTitle,
      description: metaDescription,
      path: location.pathname,
      pageContent,
      productontologyRelations: post.productontologyRelations,
      procedureLocation: post.location || "",
      procedureName: post.nameSingular
    }
  }

  return (
    <Layout navSpacer pageTitle="procedure-page" language={language}>
      <SEO
        lang={language}
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        schemaData={schemaData}
        keywords={post.metaKeywords}
        youtubeSchemas={pageContext.youtubeSchemasJson}
      />

      <SingleProcedureHero
        hero={post.hero}
        heading={post.heading}
        language={language}
        // youtube={post.youtube}
        dropdownButton={post.dropdownButton}
        goBackUrl={backUrl}
      />

      {/* {language == "en" && (
          <TestimonialGrid language={language} reviews={wallReviews} />
        )} */}

      {post.dropdownButton.hasThisSection && (
        <div
          className="is-hidden-desktop body-sections"
          style={{ maxWidth: "200px", marginInline: "auto", padding: 0 }}>
          <DropdownButton
            isCentered
            heading="Other Locations"
            links={post.dropdownButton.links}
          />
        </div>
      )}

      {post.youtube && (
        <Section zeroTopMobile zeroBottomMobile>
          <Columns sideColumnsSize={6}>
            <div className="column">
              <TheaterVideo
                buttonClass="contained"
                videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                controls
                playing
                overVideo
                language={language}>
                <Image publicId={post.youtubeThumb} useAR />
              </TheaterVideo>
            </div>
          </Columns>
        </Section>
      )}

      <div>{bodySections}</div>

      {/* {reviewsPageIsOn &&
          fixedFacewallReviews.length >= 4 &&
          language !== "es" && (
            <div className={facewallClasses}>
              <FixedFacewall
                blurb={fixedFacewallBlurb}
                heading={fixedFacewallHeading}
                sideColumnIs={1}
                middleColumnsis={1}
                reviews={fixedFacewallReviews}
                isGeneric={isFacewallTextGeneric(post, originalReviewCount)}
                button
                hideProcedureNames={
                  !isFacewallTextGeneric(post, originalReviewCount)
                }
              />
            </div>
          )} */}

      {post.ctaSection && (
        <section
          className="body-sections section joshua-tree-content cta-with-button"
          style={{ paddingTop: "0" }}>
          <div className="columns">
            <div className="column is-5" />
            <div className="column">
              <Text text={cta} />{" "}
            </div>
            <div className="column is-5" />
          </div>
          <div className="columns has-text-centered">
            <div className="column">
              <Button buttonText="Get Started" href="/get-started/" />
            </div>
          </div>
        </section>
      )}

      {reviewsPageIsOn &&
        fixedFacewallReviews.length >= 4 &&
        language !== "es" && (
          <FixedFacewall
            blurb={fixedFacewallBlurb}
            heading={fixedFacewallHeading}
            sideColumnIs={1}
            middleColumnsis={1}
            reviews={fixedFacewallReviews}
          />
        )}

      {reviewsPageIsOn && reviewRowsReviews.length > 0 && language !== "es" && (
        <ReviewRowsSection
          reviews={reviewRowsReviews}
          nameSingular={post.nameSingular}
          classnames="di-reviews"
        />
      )}

      {post.faqSection.hasThisSection && (
        <div
          id="faq"
          className={`body-sections faq-section ${
            post.faqSection.colorBack ? "color-back" : ""
          }`}>
          <div className="columns">
            <div className="column is-5" />
            <div className="column">
              <Text useStringReplace text={post.faqSection.text} />
            </div>
            <div className="column is-5" />
          </div>
        </div>
      )}

      <CallToAction
        colorBack={post.ctaColorBack}
        heading={
          post.useCustomCta ? post.getStarted.heading : getStartedData.heading
        }
        blurb={post.useCustomCta ? post.getStarted.blurb : getStartedData.blurb}
        buttons={
          post.useCustomCta ? post.getStarted.buttons : getStartedData.buttons
        }
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($title: String!, $procedureName: [String], $language: String!) {
    allUniquePagesJson(
      filter: { template: { eq: "procedures" }, language: { eq: $language } }
      sort: { fields: template }
    ) {
      nodes {
        title
        language
      }
    }
    allTemplateSettingsJson(
      filter: { templateType: { eq: "single-procedure" } }
    ) {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        getStartedSpanish {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
      }
    }
    allProcedures: allProceduresJson(filter: { title: { eq: $title } }) {
      nodes {
        category
        title
        name
        heading
        hero {
          image
          imageMobile
          animationStyle
          position
          size
          className
        }
        nameSingular
        youtube
        youtubeThumb
        procedureLevel
        dropdownButton {
          hasThisSection
          heading
          links {
            link {
              text
              title
            }
          }
        }
        bodySections {
          heading
          paragraph
          textSection
          imageTextReverse
          imageId
          # image
          # url
          extraText
          colorBack
          type
          youtube
          youtubePhotoPublicId
          videoSideColumnIs
          headingSideColumnIs
          sideColumnIs
          headingLevel
          headingLook
          colorBack
          featuredListHeading
          featuredListItems {
            featuredListItem {
              featuredListItemBlurb
              featuredListItemHeading
            }
          }
          # buttons {
          #   button {
          #     buttonText
          #     href
          #     appearance
          #     destination
          #   }
          # }
        }
        faqSection {
          hasThisSection
          text
          colorBack
        }
        ctaColorBack
        useCustomCta
        getStarted {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          blurb
          heading
        }
        metaTitle
        metaDescription
        productontologyRelations
        schemas {
          presets
          custom
        }
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: $procedureName } }
          }
        }
        reviewType: { eq: "Patient" }
        language: { eq: "en" }
      }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: "en" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    anesthesiaJson: allUniquePagesJson(
      filter: { template: { eq: "anesthesia-options" } }
    ) {
      nodes {
        anesthesiaTypes {
          blurb
          heading
        }
      }
    }
  }
`
// S DOS Customization
function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-3" />}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-8" : "is-8"
              }`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover-link"
                href={item.href}>
                <Image publicId={item.image} />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className="standard-button contained image-button">
                  Learn More
                </a>
              </a>
            </div>
            {i === 0 && <div className="column is-2" />}
            {i === 1 && <div className="column is-3" />}
          </>
        )
      })}
    </div>
  )
}

function Grid(props) {
  // Split into rows of 2
  const chunk_size = props.rowsOf
  const arr = props.pages
  const groups = arr
    .map((e, i) => (i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null))
    .filter(e => e)

  return groups.map((row, i) => (
    <GridRow key={`media-coverage-row-${i}`} items={row} />
  ))
}

export default SingleProcedure
